import Header from 'components/Header'
import Menu from 'components/Menu'
import FooterNew from 'components/FooterNew'

import Investments from './components/Investments'
import SectionKnowMore from './components/SectionKnowMore'
import SectionDataSheet from './components/SectionDatasheet'
import LegalTerms from './components/LegalTerms'
import SectionReadOurLetter from './components/SectionReadOurLetter'
import Hero from './components/Hero'
import SectionForm from 'components/SectionForm'
import Sidebar from 'components/Sidebar'

import enhancer from './hooks'


const Management = () => {
  return (
    <div>
      <Header />
      <Hero />
      <Menu />
      {/* <SectionKnowMore /> */}
      <Sidebar>
        <SectionForm customClass="sidebar" />
      </Sidebar>
      <SectionReadOurLetter />
      {/* <Investments /> */}
      {/* <SectionDataSheet /> */}
      <LegalTerms />
      <FooterNew />
    </div>
  )
}

export default enhancer(Management)
