
export const accordionList = [
  {
    title: '#1 Diversificação inteligente',
    description: 'A Previdência Definitiva da Onze garante exposição a todas as classes de investimento (Renda Fixa, Juros, Inflação, Câmbio, Bolsa Brasil, Bolsa EUA) com alocação calibrada pelo nosso time de gestão de acordo com modelos matemáticos avançados, sempre adaptados ao momento econômico do país.'
  },
  {
    title: '#2 Proteção contra crises',
    description: 'Mecanismo de proteção contra risco para momentos de grande volatilidade - construído a partir de cenários reais de crise (ex. Corona Vírus, Joesley Day). Na prática, as posições de risco são reduzidas ou zeradas automaticamente em cenários de aumento da volatilidade.',
  },
  {
    title: '#3 Menores taxas',
    description: 'A Previdência Definitiva da Onze utiliza instrumentos eficientes e de baixo custo (ex. fundos de índice) que nos permitem buscar melhores retornos com taxas até 40% menores que as da indústria. Na Previdência Definitiva não há taxas escondidas pagas pelo fundo consumindo a sua rentabilidade.',
  },
  {
    title: '#4 Menos imposto',
    description: 'Potencializado pelos benefícios tributários da previdência, seu dinheiro pode render até 30% mais se comparado com fundos de outras categorias.',
  },
]