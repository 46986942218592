export const termsList = [
  {
    label: 'Política de privacidade',
    link: 'https://www.onze.com.br/politica-de-privacidade',
  },
  {
    label: 'Política de Gestão de Risco',
    link:
      'https://www.onze.com.br/blog/wp-content/uploads/2024/04/Política-de-Gestão-de-Risco_2024.03.25.pdf',
  },
  {
    label: 'Política de Rateio e Divisão de Ordens',
    link:
    'https://www.onze.com.br/blog/wp-content/uploads/2024/04/Politica-de-Rateio-e-Divisao-de-Ordens_2024.01.15-1.pdf',
  },
  {
    label: 'Formulário de Referência',
    link:
      'https://www.onze.com.br/blog/wp-content/uploads/2024/04/Formula%CC%81rio-de-Refere%CC%82ncia-Gestora_2023.12_site.pdf',
  },
  {
    label: 'Política de Investimentos Pessoais',
    link:
      'https://www.onze.com.br/blog/wp-content/uploads/2024/04/Política-de-Investimentos-Pessoais_2024.01.15.pdf',
  },
  {
    label: 'Código de Ética',
    link:
      'https://www.onze.com.br/blog/wp-content/uploads/2024/04/Código-de-Ética_2024.03.pdf',
  },
  {
    label: 'Manual de Risco de Crédito',
    link:
      'https://www.onze.com.br/blog/wp-content/uploads/2024/04/Manual-de-Risco-de-Credito_Abr24.pdf',
  },
  {
    label: 'Manual de Compliance',
    link:
      'https://www.onze.com.br/blog/wp-content/uploads/2024/04/Manual-de-Compliance_2024-1.pdf',
  },
]
