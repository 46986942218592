import Icon from 'rv-components-onze/src/Icon'
import Typography from 'rv-components-onze/src/Typography'

import enhancer from './hooks'

const Accordion = ({ onAccordionClick, isOpen, title, description }) => {
  return (
    <div className={`accordion-management ${isOpen ? 'accordion-management--open' : ''}`} >
      <div className="accordion-management__holder">
        <div className="accordion-management__clickable" onClick={onAccordionClick}>
          <Typography as="h4" variant="subtitle" className="accordion-management__title" >
            {title}
          </Typography>
          <Icon
            className="accordion-management__icon"
            iconId={`${isOpen ? 'angle-up' : 'angle-down'}`}
          />
        </div>
        <div className="accordion-management__body">
          <Typography as="p" variant="body1" className="accordion-management__subtitle">
            {description}
          </Typography>
        </div>
      </div>
    </div >
  )
}

export default enhancer(Accordion)
