import Typography from 'rv-components-onze/src/Typography'
import Icon from 'rv-components-onze/src/Icon'

import { termsList } from './constants'

const LegalTerms = () => {
  return (
    <section className="legal-terms">
      <div className="container">
        <Typography as="h2" variant="headline5" className="legal-terms__title">
          Termos Legais
        </Typography>

        <div className="legal-terms__block">
          <div className="legal-terms__list">
            {termsList.map((term, key) => {
              return (
                <a
                  href={term.link}
                  target="__blank"
                  className="legal-terms__item"
                  key={`legal-terms__item-${key}`}>
                  <Icon iconId="transverse-up-arrow" />
                  <Typography as="h2" variant="body1">
                    {term.label}
                  </Typography>
                </a>
              )
            })}
          </div>
          <img className="legal-terms__certification-image" src="/images/anbima-gestao-recursos.png" alt="anbima" />
        </div>
      </div>
    </section>
  )
}

export default LegalTerms
