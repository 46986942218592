import Typography from 'rv-components-onze/src/Typography'
import Button from 'rv-components-onze/src/Button'

import Img from 'components/Img'

const SectionReadOurLetter = () => {

  return (
    <section className="section-read-letter" data-show>
      <figure className="section-read-letter__image-wrapper">
        <Img
          className="section-read-letter__image"
          src='/rebranding-letter-img.png'
          alt="Imagem ilustrativa de um grafico de pizza"
        />
      </figure>
      <div className="section-read-letter__description">
        <Typography as="h2" variant="headline5" className="section-read-letter__title">
          Saiba como o seu dinheiro está sendo investido
        </Typography>
        <Button
          label="Conferir os materiais de divulgação"
          as="a"
          target="_blank"
          href="https://materiais.onze.com.br/onze-gestora-fundos#portfolio-fundos"
          iconId="right-arrow"
          iconPosition="right"
          buttonStyle="text"
          customClass="section-read-letter__button"
        />
      </div>
    </section>
  )
}

export default SectionReadOurLetter
