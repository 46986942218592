import { useState } from "react"

const toggleAccordion = () => {
   const [isAccordionActive, setIsAccordionActive] = useState(false)

   const toggleAccordionState = () => {
    setIsAccordionActive(!isAccordionActive)
   }

   return {
       isAccordionActive,
       toggleAccordionState
   }
}

export default toggleAccordion