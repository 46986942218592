import { useState } from 'react'

const OpenAccordion = () => {
  const [isOpen, toggleState] = useState(false)

  const onAccordionClick = () => {
    toggleState(!isOpen)
  }

  return {
    onAccordionClick,
    isOpen,
  }
}

export default OpenAccordion
