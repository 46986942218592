import AccordionItem from './components/AccordionItem'

const GenericAccordion = ({
    accordionData,
    titleTypographyVariant,
    decriptionTypographyVariant,
  }) => {
    return(
        <ul className="generic-accordion">
            {accordionData.map((item, index )=>{
                return(
                    <AccordionItem 
                        title={item.title}
                        titleTypographyVariant={titleTypographyVariant}
                        description={item.description}
                        decriptionTypographyVariant={decriptionTypographyVariant}
                        key={`${item.title}-${index}`}
                    />
                )
            })}
        </ul>
    )
}

export default GenericAccordion