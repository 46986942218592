import GenericAccordion from 'components/GenericAccordion'
import Typography from 'rv-components-onze/src/Typography'
import Img from 'components/Img'

const Investments = () => {
    const investmentsSectionAccordionData = [
        {
            title: 'Renda Fixa',
            description: 'A parcela de renda fixa é composta por títulos públicos que podem ser classificados em ativos pós-fixados, cuja rentabilidade acompanha a taxa básica de juros (Selic); prefixados, que remuneram uma taxa estabelecida no momento da negociação; e indexados à inflação, cuja rentabilidade varia de acordo com a inflação do período. Utilizaremos como ativos os títulos públicos (LFT, LTN, NTN-Fs e NTN-Bs) e futuros da B3.'
        },
        {
            title: 'Proteções',
            description: 'O posicionamento em Dólar visa capturar retornos e proteger a carteira em momento de crises - que tendem a afetar mais significativamente os países emergentes. Além disso, a moeda proporciona historicamente uma correlação negativa a ativos brasileiros, sendo de grande valia para diversificação dos investimentos. Utilizaremos como ativo o futuro de Dólar.'
        },
        {
            title: 'Bolsa Brasil',
            description: 'Na classe de Ações Brasil buscamos capturar o crescimento das empresas que constituem o Índice Bovespa. A parcela de renda variável proporciona maior potencial de ganhos no longo prazo, combinando exposição à volatilidade, um elemento importante, com demais instrumentos da carteira. Utilizaremos como ativos o ETF BOVA11 e o futuro de índice, que replicam o Ibovespa.'
        },
        {
            title: 'Bolsa EUA',
            description: 'Por meio do investimento em ações dos EUA (S&P500) é possível capturar o crescimento das maiores empresas do mundo - uma excelente categoria para composição dos investimentos. Utilizaremos como ativos o ETF IVVB11 e o futuro de índice, que replicam o índice norte-americano.'
        },
    ]

    return (
        <section className="investments-section container">
            <div className="investments-section__text-side">
                <Typography as='h2' variant="headline4" className="investments-section__title">
                    Você investirá em:
                </Typography>

                <GenericAccordion
                    accordionData={investmentsSectionAccordionData}
                    titleTypographyVariant='subtitle'
                    descriptionTypographyVariant='body1'
                />

                <Typography as="p" variant="caption" className="investments-section__bottom-text-desk">
                    **As alocações mudam com o tempo para balancear <strong>risco x retorno</strong>
                </Typography>
            </div>
            <div className="investments-section__img-side">
                <Img src='/rebranding-investments-graph-img.png' className="investments-section__img" alt="Gráfico demonstrando que 65% da carteira é em renda fixa, 15% investido em bolsa Brasil, 10% em bolsa EUA e 10% de Proteções"/>
                <Typography as="p" variant="caption" className="investments-section__bottom-text-mobile">
                    **As alocações mudam com o tempo para balancear <strong>risco x retorno</strong>
                </Typography>
            </div>

        </section>
    )
}

export default Investments