import Typography from 'rv-components-onze/src/Typography'
import Icon from 'rv-components-onze/src/Icon'

import Accordion from '../Accordion'
import { accordionList } from './mock'

const SectionKnowMore = () => {
  return (
    <section className="section-know-more">
      <div className="section-know-more__body">
        <div className="container section-know-more__content">
          <div className="section-know-more__body-title">
            <Icon
              className="section-know-more__icon"
              iconId='grafismo-onze2'
            />
            <Typography as="h2" variant="headline5" weight="bold" className="section-know-more__subtitle">
              Conheça um dos fundos que oferecemos: Previdência Definitiva
            </Typography>
          </div>
          <div className="section-know-more__accordion">

            {
              accordionList.map(({ title, description }, index) => (
                <Accordion
                  key={`accordion-${index}`}
                  title={title}
                  description={description}
                />
              ))
            }
          </div>
        </div>
      </div>
    </section>
  )
}

export default SectionKnowMore