import Typography from 'rv-components-onze/src/Typography'
import Icon from 'rv-components-onze/src/Icon'
import regulamento_arroj from 'static/documents/regulamento_arroj.pdf'

import Img from 'components/Img'

const SectionDatasheet = () => {
  return (
    <section className="section-datasheet">
      <div className="container">
        <div className="section-datasheet__container">
          <div className="section-datasheet__header">
            <Typography
              as="h2"
              variant="headline5"
              className="section-datasheet__title">
              Ficha técnica
            </Typography>
            <Img className="section-datasheet__svg" src="/Dots.svg" aria-hidden="true" />
          </div>

          <div className="section-datasheet__body">
            <div className="section-datasheet__content">
              <Typography
                as="h4"
                variant="body2"
                className="section-datasheet__content-title">
                Taxa de Administração
              </Typography>
              <Typography
                as="p"
                variant="body1"
                className="section-datasheet__content-text">
                0.9% a.a. (ou R$ 9 ao ano para cada R$ 1000 investidos)
              </Typography>
            </div>

            <div className="section-datasheet__content">
              <Typography
                as="h4"
                variant="body2"
                className="section-datasheet__content-title">
                Taxa de Performance
              </Typography>
              <Typography
                as="p"
                variant="body1"
                className="section-datasheet__content-text">
                15% sobre o que exceder o CDI (nós só ganhamos quando você
                ganha)
              </Typography>
            </div>

            <div className="section-datasheet__content">
              <Typography
                as="h4"
                variant="body2"
                className="section-datasheet__content-title">
                Entrada e saída
              </Typography>
              <Typography
                as="p"
                variant="body1"
                className="section-datasheet__content-text">
                Não há
              </Typography>
            </div>

            <div className="section-datasheet__content">
              <Typography
                as="h4"
                variant="body2"
                className="section-datasheet__content-title">
                Tempo para resgatar
              </Typography>
              <Typography
                as="p"
                variant="body1"
                className="section-datasheet__content-text">
                D+2 (dias úteis) até cotização e D+3 (dias úteis) até o
                pagamento.
                <br />
              </Typography>
            </div>

            <div className="section-datasheet__border"></div>

            <div className="section-datasheet__content">
              <Typography
                as="h4"
                variant="body2"
                className="section-datasheet__content-title">
                Regulamentação
              </Typography>
              <Typography
                as="p"
                variant="body1"
                className="section-datasheet__content-text">
                Somos regulados pelas entidades mais importantes do mercado de
                investimentos e de seguros.
              </Typography>
            </div>

            <div className="section-datasheet__footer">
              <ul className="section-datasheet__images">
                <li className="section-datasheet__image">
                  <Img
                    className="section-datasheet__figure"
                    src="/logos-gestão_zurich@2x.png"
                    alt="Zurich"
                  />
                </li>
                <li className="section-datasheet__image">
                  <Img
                    className="section-datasheet__figure"
                    src="/logos-gestão_cvm@2x.png"
                    alt="CVM"
                  />
                </li>
                <li className="section-datasheet__image">
                  <Img
                    className="section-datasheet__figure"
                    src="/logos-gestão_ambima@2x.png"
                    alt="anbima"
                  />
                </li>
              </ul>

              <a
                href={regulamento_arroj}
                className="section-datasheet__regulation"
                target="_blank">
                <Icon iconId="pdf" customClass="section-datasheet__icon" />
                <Typography
                  as="p"
                  variant="caption"
                  className="section-datasheet__regulation-title">
                  Regulamento
                </Typography>
                <Typography
                  as="p"
                  variant="body1"
                  className="section-datasheet__regulation-text">
                  Baixe agora em PDF
                </Typography>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default SectionDatasheet
