import Typography from 'rv-components-onze/src/Typography'
import Icon from 'rv-components-onze/src/Icon'

import enhancer from './hooks'

const AccordionItem = ({
    isAccordionActive,
    toggleAccordionState,
    title,
    titleTypographyVariant,
    description,
    descriptionTypographyVariant
}) => {

    const accordionToggleClass = isAccordionActive ? 'generic-accordion__trigger active' : 'generic-accordion__trigger'

    return (
    <li className='generic-accordion__block'>
        <div className={accordionToggleClass} onClick={toggleAccordionState}>
            <Typography as="h3" weight="normal" variant={titleTypographyVariant} className='generic-accordion__title'>
                {title}
            </Typography>
            <Icon 
                iconId="angle-down"
                customClass='generic-accordion__icon'
            />
        </div>
        <div className="generic-accordion__panel">
            <Typography as="p" variant={descriptionTypographyVariant} className='generic-accordion__description'>
                {description}
            </Typography>
        </div>
    </li>
    )
}

export default enhancer(AccordionItem)